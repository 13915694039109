import { IDP, PROFILE_URL } from './vars'

/**
 * The process.env variables must be defined in the vars.js file so that our build task can also read them and ensure they exist so that compilation can succeed
 */

export const environment = {
  production: true,
  IDP: IDP,
  PROFILE_URL: PROFILE_URL,
  APP_INSIGHT_CON_STRING: null
}
