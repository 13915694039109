import * as React from 'react'
import { FunctionComponent, useEffect, useState, useContext } from 'react'
import * as Ariakit from '@ariakit/react'

import { IoCloseSharp as CloseIcon } from 'react-icons/io5'
import { Button } from '@alpha-international/alpha-ui'

import PDFPreview from '../pdf-preview/pdf-preview'
import { PiDownloadSimpleBold } from 'react-icons/pi'

interface PDFPreviewModalProps {
  attachment: any
}
const PDFPreviewModal: FunctionComponent<PDFPreviewModalProps> = ({
  attachment
}) => {
  if (!attachment?.attachedDocument?.url && !attachment?.discussionGuide.url) {
    return null
  }

  const documentSource =
    attachment?.attachedDocument || attachment?.discussionGuide

  const { url, filesize, file } = documentSource
  const { title, description } = attachment

  const dialog = Ariakit.useDialogStore()
  const toggleVisibility = () => {
    dialog.hide()
  }

  useEffect(() => {
    dialog.show()
  }, [])

  const handleDismiss = (event) => {
    if (event.button === 0) {
      dialog.hide()
    }
  }

  const handleDownload = () => {
    if (url) {
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download',file)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      console.log('file not found')
    }
  }

  return (
    <Ariakit.Dialog
      store={dialog}
      hideOnInteractOutside={false}
      backdrop={
        <div
          className='fixed inset-0 bg-black opacity-50'
          onMouseDown={handleDismiss}
        />
      }
      className='fixed z-50 inset-3 w-5/6 max-w-screen-lg overflow-x-hidden bg-white text-black mx-auto flex justify-center react-video-modal'
    >
      <div className='w-full relative'>
        <div
          className='cursor-pointer absolute z-10 right-0 top-0 md:right-6 md:top-6 p-2 text-black'
          onClick={toggleVisibility}
          data-qa-name='button-closePdfPreview'
        >
          <CloseIcon className='fill-black text-3xl' />
        </div>

        <div className='p-6 lg:p-10 flex flex-col justify-stretch gap-4 md:gap-6 h-full box-border'>
          <PDFPreview attachmentUrl={url} />
          <div className='flex flex-col justify-between lg:flex-row gap-6 sm:gap-8'>
            <div className='flex-2 flex flex-col gap-4 justify-between min-w-0 pb-4'>
              <div className='flex flex-col gap-6 max-w-md'>
                <div className='flex flex-col gap-2'>
                  <h1 className='my-0 leading-8'>{title}</h1>
                  <small className='font-semibold text-gray-400'>
                    {filesize ? filesize : ''}
                    {filesize && file ? '  /  ' : ''}
                    {file ? file : ''}{' '}
                  </small>
                </div>
                {description ? (
                  <p className='my-0 text-base font-body leading-normal'>
                    {description}
                  </p>
                ) : null}
                <Button
                  onClick={handleDownload}
                  data-qa-name='button-pdfDownload'
                  className='w-full flex gap-1 justify-center h-fit text-base items-center border-0 cursor-pointer hover:bg-alpha-red-140 max-w-full sm:max-w-max'
                >
                  <PiDownloadSimpleBold size={'1.5rem'} color='white' />
                  {$localize`:common.words|Common Words - Download@@commonWordsDownload:Download`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Ariakit.Dialog>
  )
}

export default PDFPreviewModal
